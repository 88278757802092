    import React from 'react';
    import './Plans.css';
    import {plansData} from '../../data/plansData';
    import whiteTick from '../../assets/whiteTick.png'

    const Plans = () => {
    return (
        <div className='Plans'>
            <div className='blur blur-plan-1'></div>
            <div className='blur blur-plan-2'></div>
            <div className='plans-header'>  
                <span className='stroke-text'>ready to start </span>
                <span>your journey </span>
                <span className='stroke-text'>Now with us</span>
            </div>
            <div className='plans'>
                {plansData.map((plan, i)=>(
                    <div className='plan-data' key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>₹ {plan.price}</span>
                        <div className='features'>
                            {plan.features.map((feature, i)=>(
                                <div className='feature'>
                                    <img src={whiteTick} alt=''/>
                                    <span key={i}>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div className='join' >
                            <span>See more benefits -</span>
                        </div>
                        <button className='btn'>Join Now</button>
                    </div>
                ))}
            </div>
            
        </div>
    )
    }

    export default Plans