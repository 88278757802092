import React from 'react'
import './Hero.css';
import Header from '../Header/Header';
import h_img from '../../assets/hero_image.png';
import h_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
const Hero = () => {
  const transition = {type:"spring", duration: 2}
  const mobile = window.innerWidth <= 768? true: false;
  return (
    <div className="hero">
      <div className='blur blur-hero'>

      </div>
        <div className='left-h'>
            <Header/>
            <div className='best'>
                <motion.div
                  initial={{left: mobile?'120px':'220px'}}
                  whileInView={{left:'8px'}}
                  transition={{...transition, type: 'tween'}}
                >
                </motion.div>
                <span>the best fitness club in Kharadi</span>
            </div>
            <div className='hero-text'>
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span className='your'>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                  <span>
                    In here we will help you to shape and build your ideal body and live up your life to fullest.
                  </span>
                </div>
            </div>
            <div className='figures'>
              <div>
                <span><CountUp start={54} end={142} duration={3.5}suffix='+'/></span>
                <span>expert coaches</span>
              </div>
              <div>
              <span><CountUp start={9005} end={9787} duration={3.5}suffix='+'/></span>
                <span>members joined</span>
              </div>
              <div>
              <span><CountUp start={12} end={54} duration={3.5}suffix='+'/></span>
                <span>fitness programs</span>
              </div>
            </div>
            <div className='hero-btn'>
              <button className='btn'>Get Started</button>
              <button className='btn'>Learn More</button>
            </div>
        </div>
        <div className='right-h'>
            <button className='btn'>Join Now </button>
            <motion.div className='heart-img'
              transition={transition}
              whileInView={{right:'4rem'}}
              initial={{right:'-1rem'}}
            >
              <img src={heart} alt='' />
              <span>Heart Rate </span>
              <span>123 BPM</span>
            </motion.div>
            <img src={h_img} className='hero-img-front' alt=""/>
            <motion.img 
              initial={{right:'11rem'}} 
              transition={transition}
              whileInView={{right:'20rem'}}
              src={h_back} className='hero-img-back' alt=""/>
            <motion.div className='calories'
                initial={{right:'37rem'}}
                transition={transition}
                whileInView={{right:'28rem'}}
            >
                <img src={calories} className='calories-img' alt=''/>
                <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>
            
        </div>
    </div>
  )
}
export default Hero;