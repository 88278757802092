import React from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_619xyub', 'template_p1axjrl', form.current, '3I4YfUx13KxH1Gkg6')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='Join'>
        <div className='left-j'>
            <hr/>
            <div>
                <span className='stroke-text'>Ready to</span>
                <span>level up</span>
            </div>
            <div>
                <span>your body</span>
                <span className='stroke-text'>with us</span>
            </div>
        </div>
        <div className='right-j'>
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user-email' placeholder='Enter your EmailId' />
                <button className='btn join-btn'>Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join