import React from 'react'
import './Footer.css';
import GitHub from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import LinkedIn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
const Footer = () => {
  return (
    <div className='Footer'>
        <hr/>
        <div className='footers' >
            <div className='footer-social'>
                  <a href='https://github.com/lagger8833' target='_blank'><img className='social-image' src={GitHub} alt='' /></a>
                  <a href='https://www.linkedin.com/in/vighneshwar-manchikatla' target='_blank'><img className='social-image' src={LinkedIn} alt=''/></a>
                  <a href='https://instagram.com/not_extrovertt' target='_blank'><img className='social-image' src={Instagram} alt='' /></a>
            </div>
            <div className='footer-logo'>
                <img src={Logo} alt='' />
            </div>
        </div>
        <div className='blur blur-footer-1'></div>
        <div className='blur blur-footer-2'></div>
    </div>
  )
}

export default Footer